@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

@font-face {
    font-family: "E-Ukraine";
    src: url("./assets/fonts/e-Ukraine-Regular.eot");
    src: url("./assets/fonts/e-Ukraine-Regular.ttf") format('truetype'),
         url("./assets/fonts/e-Ukraine-Regular.woff") format('woff'),
         url("./assets/fonts/e-Ukraine-Regular.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "E-Ukraine";
    src: url("./assets/fonts/e-Ukraine-Light.eot");
    src: url("./assets/fonts/e-Ukraine-Light.ttf") format('truetype'),
         url("./assets/fonts/e-Ukraine-Light.woff") format('woff'),
         url("./assets/fonts/e-Ukraine-Light.woff2") format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "E-Ukraine";
    src: url("./assets/fonts/e-Ukraine-Medium.eot");
    src: url("./assets/fonts/e-Ukraine-Medium.ttf") format('truetype'),
         url("./assets/fonts/e-Ukraine-Medium.woff") format('woff'),
         url("./assets/fonts/e-Ukraine-Medium.woff2") format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "E-Ukraine-Head";
    src: url("./assets/fonts/e-UkraineHead-Medium.eot");
    src: url("./assets/fonts/e-UkraineHead-Medium.ttf") format('truetype'),
         url("./assets/fonts/e-UkraineHead-Medium.woff") format('woff'),
         url("./assets/fonts/e-UkraineHead-Medium.woff2") format('woff2');
    font-weight: 500;
    font-style: normal;
}